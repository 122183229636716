import React from 'react';
import { Button, Typography } from '@mui/material';
import axios from 'axios';
import { ThemeProvider } from '@mui/material/styles';
import ResearchTheme from '../styles/Theme.js';


function Step4FinalReview({ userData, onComplete }) {

  const handleSubmit = async () => {
    try {
      const response = await axios.post('/api/v1/final-review/', {
        userID: userData.userID,
        researchID: userData.researchID,
        // Include other necessary data
      });
      if (response.data.success) {
        onComplete();
      }
    } catch (error) {
      console.error('Error finalizing review:', error);
    }
  };

  return (
    <ThemeProvider theme={ResearchTheme}>
      <div>
        <Typography variant="h5" gutterBottom>
          Final Review
        </Typography>
        {/* Display summary or final content using userData */}
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Finish
        </Button>
      </div>
    </ThemeProvider>
  );
}

export default Step4FinalReview;
