import { api } from '../services/api';

  
// Add this function to apiUtils.js
export const pollResearchStatus = async (userID, researchID, statusAttribute, maxAttempts = 30, pollInterval = 3000) => {
  let attempts = 0;
  
  while (attempts < maxAttempts) {
    try {
      const research = await api.getPreviousResearch(userID, researchID);
      console.log("inside pollResearchStatus, research is",research);
      const status = research?.[statusAttribute];
      
      if (research && status === 'COMPLETED') {
        return research;
      } else if (research && status === 'ERROR') {
        throw new Error('Failed to generate survey questions, status is ERROR');
      }
      
      // Wait before trying again
      await new Promise(resolve => setTimeout(resolve, pollInterval));
      attempts++;
    } catch (error) {
      console.error('Error polling research status:', error);
      throw error;
    }
  }
  
  throw new Error('Timeout waiting for generation to complete');
};


export function mapServerQuestionsToLocal(questionsObject) {
  /**
   * questionsObject is the response from the server, like:
   * {
   *   q1: { prompt: '...', kind: 'multiple-choice', vocab: ['Yes','No'], ... },
   *   q2: { prompt: '...', kind: 'open', max_length: 200, ... },
   * }
   * So let's turn that into an array of question objects with
   * questionID, questionText, questionType, responseOptions, etc.
   */
  if (questionsObject === null) {
    return null;
  }
  return Object.keys(questionsObject).map((questionID) => {
    const questionData = questionsObject[questionID];
    const isMultipleChoice = questionData.kind === 'multiple-choice';

    let selectMin;
    let selectMax;

    if (isMultipleChoice) {
      // If min/max exist, use them; otherwise default
      selectMin = typeof questionData.min !== 'undefined' ? questionData.min : 1;
      selectMax = typeof questionData.max !== 'undefined' ? questionData.max : questionData.vocab.length;
    }

    return {
      questionID,
      questionText: questionData.prompt,
      questionType: questionData.kind,
      responseOptions: isMultipleChoice ? questionData.vocab : [],
      maxLength: questionData.kind === 'open' ? questionData.max_length : undefined,
      selectMin,
      selectMax,

      // If you also track fixed percentages, you can handle them here
      hasFixedPercentages: questionData.hasFixedPercentages || false,
      responsePercentages: questionData.responsePercentages || [],
    };
  });
}


  // Helper function to format raw API response data into the array structure you need.
  export function mapServerResponsesToLocal(apiData, researchID) {
    // apiData is expected to have the shape:
    // { personaID1: { questionID1: responseText, questionID2: responseText, ... },
    //   personaID2: { questionID1: responseText, questionID2: responseText, ... }, ... }
    if (apiData === null) {
      return null;
    }
    return Object.entries(apiData).flatMap(([personaID, questionMap]) =>
      Object.entries(questionMap).map(([questionID, response]) => ({
        personaID,
        questionID,
        response,
        researchID,
      }))
    );
  }
