import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { blogData } from '../components/blogs/blogData';
import * as blogContents from '../components/blogs';
import '../styles/BlogPage.css'; 

function getRandomBlogs(currentId, count = 3) {

  const filtered = blogData.filter((b) => b.id !== currentId);

  for (let i = filtered.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [filtered[i], filtered[j]] = [filtered[j], filtered[i]];
  }

  return filtered.slice(0, count);
}

const BlogDetailPage = () => {
  const { blogId } = useParams();
  const navigate = useNavigate();

  const blog = blogData.find((item) => item.id === blogId);
  
  const BlogContent = blogId && blogContents[blogId] 
    ? blogContents[blogId] 
    : null;

  if (!blog) {
    return <h2 style={{ textAlign: 'center' }}>Blog not found.</h2>;
  }

  const authorsText = blog.authors ? `Posted by ${blog.authors.join(', ')}` : '';
  const randomBlogs = getRandomBlogs(blogId, 3);


  return (
    <div className="blog-detail-page">
      
      <div className="blog-detail-metadata">
        <span className="blog-detail-category">{blog.category}</span>
        <h1 className="blog-detail-title">{blog.title}</h1>
        <span className="blog-detail-date">{blog.date}</span>
      </div>

      <div className="blog-detail-image-wrapper">
        <img src={`/${blog.image}`} alt={blog.title} className="blog-detail-image" />
      </div>

      <div className="blog-detail-content">
        <BlogContent />
        {authorsText && <div className="blog-detail-authors">{authorsText}</div>}
      </div>

      <div className="blog-detail-recommended">
        <h2>Recommended Blogs</h2>
        <div className="recommended-blogs-grid">
          {randomBlogs.map((item) => (
            <div
              key={item.id}
              className="recommended-blog-card"
              onClick={() => navigate(`/blogs/${item.id}#top`)}
              tabIndex={0}
              role="button"
            >
              <div className="recommended-blog-image-wrapper">
                <img
                  src={`/${item.image}`}
                  alt={item.title}
                  className="recommended-blog-image"
                />
              </div>
              <span className="recommended-blog-category">{item.category}</span>
              <h3 className="recommended-blog-title">{item.title}</h3>
              <span className="recommended-blog-date">{item.date}</span>
            </div>
          ))}
        </div>
      </div>

      </div>

  );
};

export default BlogDetailPage;
