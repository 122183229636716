import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Card,
  CardContent
} from '@mui/material';
import { api } from '../services/api';
import { ThemeProvider } from '@mui/material/styles';
import ResearchTheme from '../styles/Theme.js';

function Step2Personas({ userData, setUserData }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const [filters, setFilters] = useState({
    numberOfPersonas: userData.personaFilters?.numberOfPersonas || 5,
    gender: userData.personaFilters?.gender || [],
    province: userData.personaFilters?.province || [],
    ageRange: userData.personaFilters?.ageRange || []
  });


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const personas = await api.selectPersonas({
        userID: userData.userID,
        researchID: userData.researchID,
        numberOfPersonas: filters.numberOfPersonas,
        gender: filters.gender,
        province: filters.province,
        ageRange: filters.ageRange
      });

      // Update userData with selected personas and progress
      setUserData(prev => ({
        ...prev,
        selectedPersonas: personas,
        progress: 3, // Enable Responses tab
        personaFilters:  { ...filters }
      }));
    } catch (err) {
      setError(err.message);
      console.error('Error details:', err);
    } finally {
      setLoading(false);
    }
  };

  const PersonaCard = ({ persona }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {persona.name || `Persona ${persona.id}`}
        </Typography>
        <Typography>Age: {persona.age_range}</Typography>
        <Typography>Gender: {persona.gender}</Typography>
        <Typography>Province: {persona.province}</Typography>
      </CardContent>
    </Card>
  );

  return (
    <ThemeProvider theme={ResearchTheme}>
      <Box>
        <Typography variant="h5" gutterBottom>
          Step 2: Select Personas
        </Typography>

        <Paper sx={{ p: 3, mt: 2 }}>
          <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Number of Personas"
                    value={filters.numberOfPersonas}
                    onChange={(e) => setFilters(prev => ({
                      ...prev,
                      numberOfPersonas: parseInt(e.target.value)
                    }))}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Gender</InputLabel>
                    <Select
                      multiple
                      open={menuOpen === "gender"}
                      onOpen={() => setMenuOpen("gender")}
                      onClose={() => setMenuOpen(null)}
                      value={filters.gender}
                      onChange={(e) => {
                        setFilters(prev => ({
                        ...prev,
                        gender: e.target.value
                      }));
                      setMenuOpen(null);
                      }}
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Province</InputLabel>
                    <Select
                      multiple
                      open={menuOpen === "province"}
                      onOpen={() => setMenuOpen("province")}
                      onClose={() => setMenuOpen(null)}
                      value={filters.province}
                      onChange={(e) => {
                        setFilters(prev => ({
                        ...prev,
                        province: e.target.value
                      }));
                      setMenuOpen(null);
                      }}
                    >
                      <MenuItem value="Noord-Holland">Noord-Holland</MenuItem>
                      <MenuItem value="Zuid-Holland">Zuid-Holland</MenuItem>
                      <MenuItem value="Utrecht">Utrecht</MenuItem>
                      <MenuItem value="Gelderland">Gelderland</MenuItem>
                      <MenuItem value="Noord-Brabant">Noord-Brabant</MenuItem>
                      <MenuItem value="Limburg">Limburg</MenuItem>
                      <MenuItem value="Overijssel">Overijssel</MenuItem>
                      <MenuItem value="Flevoland">Flevoland</MenuItem>
                      <MenuItem value="Groningen">Groningen</MenuItem>
                      <MenuItem value="Friesland">Friesland</MenuItem>
                      <MenuItem value="Drenthe">Drenthe</MenuItem>
                      <MenuItem value="Zeeland">Zeeland</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Age Range</InputLabel>
                    <Select
                      multiple
                      open={menuOpen === "ageRange"}
                      onOpen={() => setMenuOpen("ageRange")}
                      onClose={() => setMenuOpen(null)}
                      value={filters.ageRange}
                      onChange={(e) => {
                        setFilters(prev => ({
                        ...prev,
                        ageRange: e.target.value
                      }));
                      setMenuOpen(null);
                      }}
                    >
                      <MenuItem value="18-24">18-24</MenuItem>
                      <MenuItem value="25-34">25-34</MenuItem>
                      <MenuItem value="35-44">35-44</MenuItem>
                      <MenuItem value="45-54">45-54</MenuItem>
                      <MenuItem value="55-64">55-64</MenuItem>
                      <MenuItem value="65+">65+</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            {/* )} */}

            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                Error: {error}
              </Typography>
            )}

            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{ mt: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Select Personas'}
            </Button>
          </Box>
        </Paper>

        {userData.selectedPersonas?.length > 0 && (
          <Paper sx={{ p: 3, mt: 3 }}>
            <Typography variant="h6" gutterBottom>
              Selected Personas
            </Typography>
            <Grid container spacing={2}>
              {userData.selectedPersonas.map((persona, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <PersonaCard persona={persona} />
                </Grid>
              ))}
            </Grid>
          </Paper>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default Step2Personas;