import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  ToggleButton,
  ToggleButtonGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { api } from '../services/api';
import { handleReportGeneration } from './ReportContent';
import ReportContent from './ReportContent';
import { mapServerResponsesToLocal, pollResearchStatus } from '../services/apiUtils';
import { ThemeProvider } from '@mui/material/styles';
import ResearchTheme from '../styles/Theme.js';

function Step3Responses({ userData, setUserData }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [viewMode, setViewMode] = useState('byQuestion'); // 'byQuestion' or 'byPersona'
  const [pollingFailed, setPollingFailed] = useState(false);

  // Add polling effect for response generation
  useEffect(() => {
    // Check if we should start polling for responses
    if (userData.researchID && userData.responsesStatus === 'PENDING' && !pollingFailed) {
      console.log("why am i here????????");
      const pollForResponses = async () => {
        try {
          const research = await pollResearchStatus(userData.userID, userData.researchID, 'responsesStatus');
          console.log("successfully polled research", research);
          if (research) {
            const responsesData = await api.getExistingResponses(userData.userID, userData.researchID);
            const responses = mapServerResponsesToLocal(responsesData, userData.researchID);

            console.log("responses after polling", responses);
            setUserData(prev => ({
              ...prev,
              surveyResponses: responses,
              progress: 4,
              responsesStatus: 'COMPLETED',
              questionResponsePending: null // Clear any pending question
            }));
            
            // Make sure to clear the loading state
            setLoading(false);
          }
        } catch (err) {
          setError(`Error polling for responses: ${err.message}`);
          setPollingFailed(true);
          setUserData(prev => ({
            ...prev,
            responsesStatus: 'ERROR'
          }));
          setLoading(false);
        }
      };
      pollForResponses();
    }
  }, [userData.responsesStatus, pollingFailed, userData.userID, userData.researchID, setUserData]);

  const handleGenerateResponses = async () => {
    setLoading(true);
    setError(null);
    setPollingFailed(false);

    try {
      await api.generateResponses(
        userData.userID,
        userData.researchID
      );

      // Important: clear the surveyResponses AND set status to PENDING
      setUserData(prev => ({
        ...prev,
        responsesStatus: 'PENDING',
        surveyResponses: null
      }));
      
      // Note: DO NOT clear loading state here, let the polling effect handle it
    } catch (err) {
      setError(err.message);
      setLoading(false);
    } finally {
        console.log("after handle generate responses the userData is",userData);
    }
  };

  const handleGenerateQuestionResponses = async questionID => {
    setError(null);

    try {
      await api.generateQuestionResponses(
        userData.userID,
        userData.researchID,
        questionID
      );
      
      // Update userData to indicate which question is being regenerated
      setUserData(prev => ({
        ...prev,
        responsesStatus: 'PENDING',
        questionResponsePending: questionID
      }));
      
      // Poll for updates
      try {
        const research = await pollResearchStatus(
          userData.userID, 
          userData.researchID, 
          'responsesStatus'
        );

        console.log("done polling, research we found is",research);
        
        if (research) {
          const result = await api.getExistingResponses(userData.userID, userData.researchID);
          const responses = mapServerResponsesToLocal(result, userData.researchID);

          setUserData(prev => ({
            ...prev,
            surveyResponses: responses,
            responsesStatus: 'COMPLETED',
            questionResponsePending: null,
            progress: 4
          }));
        }
      } catch (pollError) {
        setError(`Error waiting for question response: ${pollError.message}`);
      }
    } catch (err) {
      setError(err.message);
      setUserData(prev => ({
        ...prev,
        questionResponsePending: null
      }));
    }
  };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const organizeByQuestion = () => {
    if (!userData.surveyResponses) return [];
    
    console.log('Survey Questions:', userData.surveyQuestions);
    console.log('Results:', userData.surveyResponses);
    
    const personaIds = userData.selectedPersonas.map(persona => persona.id);
    console.log('Selected persona ids: ', personaIds);

    return userData.surveyQuestions.map(question => {
      const questionResponses = userData.surveyResponses.filter(
        r => r.questionID === question.questionID && personaIds.includes(r.personaID)
      );

      console.log(`Responses for question ${question.questionID}:`, questionResponses);

      return {
        question,
        responses: questionResponses
      };
    });
  };

  const organizeByPersona = () => {
    if (!userData.surveyResponses) return [];
    
    console.log("researchID from userData:", userData.researchID);
    console.log("userData.surveyResponses: ", userData.surveyResponses);

    return userData.selectedPersonas.map(persona => {
      const personaResponses = userData.surveyResponses.filter(
        r => parseInt(r.personaID) === parseInt(persona.id) && r.researchID === userData.researchID
      );

      console.log(`Responses for persona ${persona}:`, personaResponses);

      return {
        persona,
        responses: personaResponses
      };
    });
  };
  
  const renderResponsesByQuestion = () => {
    const organizedData = organizeByQuestion();
    
    return (
      <Box>
        {organizedData.map((item, index) => {
          // Check if this question has responses
          const hasResponses = item.responses && item.responses.length > 0;
          // Check if this question is currently being regenerated
          const isRegenerating = userData.questionResponsePending === item.question.questionID;
          // Check if any question is being regenerated (to disable other regenerate buttons)
          const isAnyQuestionRegenerating = !!userData.questionResponsePending;
          
          // If no responses, display a simpler non-accordion view
          if (!hasResponses) {
            return (
              <Paper key={index} sx={{ p: 2, mb: 2, bgcolor: '#f5f5f5' }}>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Question {index + 1}: {item.question.questionText}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  No responses yet for this question.
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={isAnyQuestionRegenerating}
                  onClick={() => handleGenerateQuestionResponses(item.question.questionID)}
                >
                  {isRegenerating ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CircularProgress size={20} sx={{ mr: 1 }} />
                      Generating...
                    </Box>
                  ) : (
                    'Generate Responses for this Question'
                  )}
                </Button>
              </Paper>
            );
          }
          
          // For questions with responses, use the accordion view
          return (
            <Accordion key={index} defaultExpanded={index === 0}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  Question {index + 1}: {item.question.questionText}
                  {isRegenerating && (
                    <Box component="span" sx={{ ml: 2, display: 'inline-flex', alignItems: 'center' }}>
                      <CircularProgress size={16} sx={{ mr: 1 }} />
                      <Typography variant="caption" color="text.secondary">
                        Regenerating...
                      </Typography>
                    </Box>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ mb: 1 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={isAnyQuestionRegenerating}
                    onClick={() => handleGenerateQuestionResponses(item.question.questionID)}
                  >
                    {isRegenerating ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size={20} sx={{ mr: 1 }} />
                        Regenerating...
                      </Box>
                    ) : (
                      'Regenerate Responses for this Question'
                    )}
                  </Button>
                </Box>
  
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Persona</TableCell>
                        <TableCell>Response</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.responses.map((response, rIndex) => (
                        <TableRow key={rIndex}>
                          <TableCell>
                            {userData.selectedPersonas.find(p => p.id === response.personaID)?.name || response.personaID}
                          </TableCell>
                          <TableCell>
                            {Array.isArray(response.response) ? (
                              response.response.map((ans, i) => (
                                <React.Fragment key={i}>
                                  {ans}
                                  {i < response.response.length - 1 && <br />}
                                </React.Fragment>
                              ))
                            ) : typeof response.response === 'string' ? (
                              response.response.split('\\n').map((line, i) => (
                                <React.Fragment key={i}>
                                  {line}
                                  {i < response.response.split('\\n').length - 1 && <br />}
                                </React.Fragment>
                              ))
                            ) : (
                              // Fallback if it's neither a string nor an array
                              <>{JSON.stringify(response.response)}</>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    );
  };
  
  const renderResponsesByPersona = () => {
    const organizedData = organizeByPersona();
    
    return (
      <Box>
        {organizedData.map((item, index) => (
          <Accordion key={index} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                Persona {item.persona.id} ({item.persona.age_range}, {item.persona.gender})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Question</TableCell>
                      <TableCell>Response</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.responses.map((response, rIndex) => {
                      const question = userData.surveyQuestions.find(q => q.questionID === response.questionID);
                      const isRegenerating = userData.questionResponsePending === response.questionID;
                      
                      return (
                        <TableRow key={rIndex}>
                          <TableCell>
                            {question?.questionText || response.questionID}
                            {isRegenerating && (
                              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <CircularProgress size={16} sx={{ mr: 1 }} />
                                <Typography variant="caption" color="text.secondary">
                                  Regenerating...
                                </Typography>
                              </Box>
                            )}
                          </TableCell>
                          <TableCell>
                            {Array.isArray(response.response) ? (
                              response.response.map((ans, i) => (
                                <React.Fragment key={i}>
                                  {ans}
                                  {i < response.response.length - 1 && <br />}
                                </React.Fragment>
                              ))
                            ) : typeof response.response === 'string' ? (
                              response.response.split('\\n').map((line, i) => (
                                <React.Fragment key={i}>
                                  {line}
                                  {i < response.response.split('\\n').length - 1 && <br />}
                                </React.Fragment>
                              ))
                            ) : (
                              // Fallback if it's neither a string nor an array
                              <>{JSON.stringify(response.response)}</>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  // Determine which view to show based on the three cases you specified
  const renderContent = () => {
    const hasResponses = userData.surveyResponses && userData.surveyResponses.length > 0;

    // CASE 2: Responses are being generated (status is PENDING and no responses yet)
    // Also include the global loading state here
    if ((userData.responsesStatus === 'PENDING' && !hasResponses) || loading) {
      return (
        <Paper sx={{ p: 4, mb: 2, textAlign: 'center', bgcolor: '#f5f5f5' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
            <CircularProgress size={40} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Generating responses...
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              This may take a minute or two. Please wait.
            </Typography>
          </Box>
        </Paper>
      );
    }
    
    // CASE 1 & 3: We have responses
    if (hasResponses) {
      return (
        <Box>
          <Box sx={{ mb: 2 }}>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={handleViewModeChange}
              aria-label="view mode"
            >
              <ToggleButton value="byQuestion" aria-label="by question">
                View by Question
              </ToggleButton>
              <ToggleButton value="byPersona" aria-label="by persona">
                View by Persona
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {viewMode === 'byQuestion' ? renderResponsesByQuestion() : renderResponsesByPersona()}
        </Box>
      );
    }
    
    // If we don't have responses and not in PENDING state - show empty state with generate button
    return (
      <Paper sx={{ p: 4, mb: 2, textAlign: 'center', bgcolor: '#f5f5f5' }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          No responses have been generated yet
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
          Click the "Generate New Responses" button below to generate responses for all questions from your selected personas.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={handleGenerateResponses}
            disabled={loading}
            size="large"
          >
            {loading ? <CircularProgress size={24} /> : 'Generate New Responses'}
          </Button>
        </Box>
      </Paper>
    );
  };

  return (
    <ThemeProvider theme={ResearchTheme}>
      <Box>
        <Typography variant="h5" gutterBottom>
          Step 3: Generate Responses
        </Typography>

        <Paper sx={{ p: 3, mt: 2 }}>
          {renderContent()}
        </Paper>
        
        {/* Only show action buttons if we have responses and status is COMPLETED */}
        {userData.surveyResponses && userData.responsesStatus === 'COMPLETED' && !loading && (
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleGenerateResponses}
              disabled={loading || userData.responsesStatus === 'PENDING'}
              sx={{ mr: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Generate New Responses'}
            </Button>

            <Button
              variant="outlined"
              onClick={() => handleReportGeneration(userData)}
              disabled={loading}
            >
              Download PDF report
            </Button>
          </Box>
        )}
        
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            Error: {error}
          </Typography>
        )}
        
        <div style={{ display: 'none' }}>
          <ReportContent userData={userData} />
        </div>
      </Box>
    </ThemeProvider>
  );
}

export default Step3Responses;
