// This file is like a css file but for MUI components. Import this to your MUI page to change the theme (other steps involved also). 

import '../index.js'
import { createTheme } from '@mui/material/styles';

const ResearchTheme = createTheme({
  palette: {
    primary: {
      main: '#649470',
    },
    secondary: {
      main: '#yourSecondaryColor',
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  // Override component styles
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Example of overriding button text transformation
        },
      },
    },
  },
});

export default ResearchTheme;
