import axios from 'axios';

// const API_BASE_URL = process.env.REACT_APP_RESEARCH_API_URL;
// const API_BASE_URL = 'http://127.0.0.1:8000';
const API_BASE_URL = "https://fufmfdzp42.execute-api.eu-central-1.amazonaws.com/dev";

const isAwsApi = API_BASE_URL.includes("amazonaws.com");

function parseApiResponse(response) {
  if (isAwsApi) {
    // AWS integration returns the data more directly
    return response.data.data;
  } else {
    // Local integration requires parsing JSON again
    const bodyData = JSON.parse(response.data.body);
    return bodyData.data;
  }
}

export const api = {
  getPreviousResearches: async (userID) => {
    console.log("load previous researches")
    try {
      const response = await axios.get(`${API_BASE_URL}/research/${userID}`);
      const result = parseApiResponse(response);
      console.log("previous researches result ", result);
      return result;

    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  deleteResearch: async (userID, researchID) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/research/remove/${userID}/${researchID}`);
      console.log('API response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  getPreviousResearch: async (userID, researchID) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/research/${userID}/${researchID}`);
      const result = parseApiResponse(response);
      console.log("get previous survey result ", result);
      return result;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  createSurvey: async (userID, surveyDescription) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/research/create`, {
        userID,
        surveyDescription
      });
      const resultCreate = parseApiResponse(response);
      console.log("create survey ", resultCreate);
      const researchID = resultCreate.researchID;
      console.log('fetched new researchID:', researchID);


      return researchID;

    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  updateSurvey: async (userID, researchID, questions_list) => {
    try {
      const questions = questions_list.map(question => ({
        questionID: question.questionID,
        prompt: question.questionText,
        kind: question.questionType,
        vocab: question.responseOptions,
        max_length: question.maxLength,
        max: question.selectMax,
        min: question.selectMin
      }));

      const response = await axios.post(`${API_BASE_URL}/research/update_questions`, {
        userID,
        researchID,
        questions
      });
      const result = parseApiResponse(response);
      console.log("update survey result ", result);
      return result.survey;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  selectPersonas: async (params) => {
    if (!params.userID || !params.researchID) {
      throw new Error('User ID and Research ID are required');
    }
    try {
      const requestBody = {
        userID: params.userID,
        researchID: params.researchID,
        numberOfPersonas: parseInt(params.numberOfPersonas),
        gender: params.gender.length > 0 ? params.gender : 'all',
        province: params.province.length > 0 ? params.province : 'all',
        ageRange: params.ageRange.length > 0 ? params.ageRange : 'all'
      };
      
      console.log('Sending persona selection request:', requestBody);
      
      const response = await axios.post(`${API_BASE_URL}/personas/create`, requestBody);
      const result = parseApiResponse(response);
      console.log("create personas result ", result);
      return result;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },


  getPreviousPersonas: async (userID, researchID) => {
    if (!userID || !researchID) {
      throw new Error('User ID and Research ID are required');
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/personas/${userID}/${researchID}`);
      const result = parseApiResponse(response);
      console.log("previous personas result ", result);
      return result;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  getAllPreviousPersonas: async (userID) => {
    if (!userID) {
      throw new Error('User ID is required');
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/personas/${userID}`);
      const result = parseApiResponse(response);
      console.log("all previous personas result ", result);
      return result;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  getExistingResponses: async (userID, researchID) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/responses/${userID}/${researchID}`);
      const result = parseApiResponse(response);
      console.log("existing responses result ", result);
      return result.responses;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  getPreviousResponseData: async (userID) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/responses/${userID}`);
      const result = parseApiResponse(response);
      console.log("all previous responses result ", result);
      return result;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },
  
  generateResponses: async (userID, researchID) => {
    await axios.post(`${API_BASE_URL}/responses/create`, {
      userID,
      researchID
    });
  },

  generateQuestionResponses: async (userID, researchID, questionID) => {
    await axios.post(`${API_BASE_URL}/responses/update_question`, {
      userID,
      researchID,
      questionID
    });
  },

  getDashboardStats: async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/dashboard/stats`);
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  getDashboardData: async (dataType, filterField = null, filterValue = null) => {
    try {
      let url = `${API_BASE_URL}/dashboard/data/${dataType}`;
      if (filterField && filterValue) {
        url += `?filter_field=${filterField}&filter_value=${filterValue}`;
      }
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  },

  deleteDashboardRecord: async (dataType, id) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/dashboard/data/${dataType}/${id}`);
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  }
};


